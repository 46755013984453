import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Project table
const Typo3Project = () => import('@/views/projects/Typo3')
const AppProject = () => import('@/views/projects/App')

// Edit form
const EditTypo3Project = () => import('@/views/projects/EditTypo3')
const EditAppProject = () => import('@/views/projects/EditApp')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Partner = () => import('@/views/pages/Partner')

// Users
/* const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User') */

Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
          path: '/login',
          params: { nextUrl: to.fullPath }
      })
    } else {
      let user = JSON.parse(localStorage.getItem('user'))
      if (user == null) {
        localStorage.removeItem('jwt')
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        })
      } else {
        if (to.matched.some(record => record.meta.is_admin)) {
          if (user.is_admin == 1) {
            next()
          } else{
            next({ name: 'Partner'})
          }
        } else {
            next()
        }
      }
    }
  } else {
      next()
  }
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      /* beforeEnter: (to, from, next) => {
        if (localStorage.getItem('jwt') == null || localStorage.getItem('user') == null) {
          next({
            path: '/login',
            params: { nextUrl: to.fullPath }
          })
        } else {
          let user = JSON.parse(localStorage.getItem('user'))
          if (user !== null) {
            if (! user.is_admin) {
              localStorage.removeItem('jwt')
              localStorage.removeItem('user')
              next({
                path: '/login',
                params: { nextUrl: to.fullPath }
              })
            }
          }
        }
      }, */
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true,
            is_admin : true
          }
        },
        {
          path: '/projects/typo3',
          name: 'TYPO3 Project List',
          component: Typo3Project
        },
        {
          path: '/projects/application',
          name: 'Application Project List',
          component: AppProject
        },
        {
          path: '/projects/typo3/:projectId/edit',
          name: 'Edit Typo3 Project',
          component: EditTypo3Project
        },
        {
          path: '/projects/application/:id/edit',
          name: 'Edit Application Project',
          component: EditAppProject
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/partner',
      name: 'Partner',
      component: Partner
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    }
  ]
}
export default router;
