// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox, 
// the second argument is the SVG content stripped of SVG tags
export const logo = ['494 90',`
<g>
<g>
  <path fill="#474E61" d="M161.153,72.628l8.208,1.416l-0.848,5.188l-17.549,0.944l3.773-17.361
    c-2.516,5.033-5.347,9.121-8.491,12.267c-1.509,1.509-3.412,2.895-5.708,4.15c-2.296,1.257-4.608,1.887-6.935,1.887
    c-3.523,0-6.463-1.57-8.821-4.717c-2.359-3.144-3.538-7.075-3.538-11.792c0-10.945,2.783-19.579,8.35-25.9
    c5.567-6.322,12.155-9.482,19.766-9.482c1.824,0,3.616,0.235,5.377,0.708c1.76,0.472,3.082,0.928,3.963,1.368l1.227,0.755
    l2.831-1.887h5.189L161.153,72.628z M155.963,47.627l1.887-9.907c-2.391-0.944-5.222-1.415-8.491-1.415
    c-5.033,0-9.404,2.28-13.115,6.84c-3.712,4.562-5.566,11.244-5.566,20.05c0,3.208,0.533,5.661,1.604,7.358
    c1.069,1.699,2.563,2.548,4.482,2.548c1.917,0,3.93-0.881,6.038-2.643c2.106-1.76,3.916-3.868,5.425-6.321
    c3.27-5.344,5.566-9.968,6.888-13.868L155.963,47.627z"/>
  <path fill="#474E61" d="M208.766,45.739l-0.283-8.02c-1.51-0.628-3.555-0.944-6.133-0.944c-5.158,0-9.279,2.595-12.36,7.784
    c-3.083,5.189-4.624,11.321-4.624,18.399c0,7.076,2.987,10.614,8.963,10.614c1.949,0,4.104-0.346,6.463-1.038
    c2.359-0.691,4.229-1.416,5.614-2.171l2.076-1.037l2.83,4.717c-0.693,0.504-1.651,1.148-2.877,1.934
    c-1.227,0.787-3.507,1.841-6.84,3.162c-3.335,1.32-6.385,1.979-9.151,1.979c-4.907,0-8.885-1.539-11.936-4.621
    c-3.051-3.082-4.576-7.359-4.576-12.833c0-10.251,2.609-18.554,7.831-24.907c5.22-6.353,11.888-9.529,20.001-9.529
    c2.265,0,4.497,0.315,6.699,0.943c2.201,0.629,3.836,1.259,4.906,1.887l1.604,0.943l-2.076,12.737H208.766z"/>
  <path fill="#474E61" d="M263.739,30.172l-2.831,14.152h-6.133V37.72c-1.95,0-3.884,0.849-5.802,2.547
    c-1.92,1.698-3.538,3.743-4.859,6.132c-2.769,5.033-4.781,9.467-6.039,13.304l-0.755,2.548l-3.019,17.926h-8.962l6.887-42.457
    l-8.02-1.887l0.849-5.189l17.36-0.472l-3.679,16.417c2.012-4.78,4.496-8.68,7.454-11.7c1.383-1.446,3.223-2.751,5.519-3.916
    c2.296-1.163,4.732-1.745,7.312-1.745C260.404,29.229,261.977,29.544,263.739,30.172z"/>
  <path fill="#474E61" d="M307.427,41.494c0,5.41-3.066,9.797-9.199,13.163c-6.134,3.365-14.106,5.047-23.919,5.047
    c-0.188,1.384-0.281,2.736-0.281,4.058c0,6.541,3.458,9.813,10.377,9.813c2.012,0,4.309-0.346,6.889-1.038
    c2.578-0.691,4.654-1.416,6.227-2.171l2.359-1.037l2.831,4.813c-0.757,0.503-1.794,1.146-3.115,1.933
    c-1.321,0.788-3.837,1.825-7.547,3.113c-3.713,1.29-7.204,1.934-10.474,1.934c-5.158,0-9.277-1.509-12.359-4.528
    c-3.082-3.018-4.623-7.013-4.623-11.98c0-9.938,2.656-18.32,7.972-25.145c5.314-6.824,11.746-10.237,19.293-10.237
    c4.781,0,8.57,1.117,11.37,3.349C306.025,34.811,307.427,37.783,307.427,41.494z M275.158,53.098
    c6.731,0,12.298-1.004,16.699-3.018c4.403-2.013,6.605-4.561,6.605-7.643c0-4.088-2.359-6.133-7.076-6.133
    c-3.46,0-6.73,1.572-9.813,4.717C278.493,44.168,276.354,48.192,275.158,53.098z"/>
  <path fill="#474E61" d="M352.746,72.628l8.114,1.416l-0.85,5.188l-17.267,0.944l4.153-17.361
    c-1.952,4.403-4.718,8.492-8.304,12.267c-1.574,1.573-3.508,2.972-5.803,4.198c-2.297,1.226-4.609,1.839-6.935,1.839
    c-3.649,0-6.638-1.556-8.963-4.67c-2.329-3.113-3.491-7.06-3.491-11.839c0-10.883,2.75-19.499,8.256-25.853
    c5.502-6.353,12.061-9.529,19.672-9.529c3.396,0,6.416,0.693,9.057,2.075l2.642-16.228l-8.019-1.887L345.859,8l17.454-0.472
    L352.746,72.628z M347.84,47.627l1.51-9.907c-2.202-0.944-4.876-1.415-8.02-1.415c-5.411,0-9.845,2.5-13.303,7.5
    c-3.461,5-5.189,11.306-5.189,18.917c0,3.334,0.519,5.896,1.556,7.688c1.038,1.792,2.548,2.689,4.529,2.689
    c1.98,0,4.009-0.881,6.085-2.643c2.077-1.76,3.836-3.868,5.283-6.321c3.02-5.03,5.221-9.654,6.606-13.868L347.84,47.627z"/>
  <path fill="#474E61" d="M371.581,80.177l6.794-42.457l-8.02-1.887l0.943-5.189l17.266-0.472l-6.792,42.456l8.019,1.416
    l-0.85,5.188L371.581,80.177z M391.395,13.425c0,1.919-0.581,3.696-1.744,5.331c-1.165,1.637-2.658,2.453-4.481,2.453
    c-1.825,0-3.209-0.425-4.151-1.274c-0.944-0.849-1.415-2.231-1.415-4.151c0-1.918,0.579-3.694,1.745-5.331
    C382.511,8.818,384.004,8,385.828,8s3.208,0.424,4.153,1.274C390.924,10.123,391.395,11.507,391.395,13.425z"/>
  <path fill="#474E61" d="M431.055,80.177l3.868-18.304c-0.817,2.075-2.06,4.325-3.726,6.746c-1.668,2.421-3.303,4.467-4.907,6.133
    c-1.602,1.667-3.679,3.146-6.226,4.434c-2.549,1.29-5.142,1.934-7.785,1.934c-6.981,0-10.473-3.834-10.473-11.509
    c0-1.447,0.156-3.271,0.472-5.474l4.341-26.417l-8.02-1.887l0.849-5.189l17.267-0.472l-5.189,32.551
    c-0.253,2.014-0.378,3.396-0.378,4.15c0,4.151,1.634,6.228,4.905,6.228c2.326,0,4.624-0.897,6.889-2.689s4.15-3.946,5.661-6.463
    c3.208-5.471,5.438-10.188,6.699-14.151l0.847-2.642l2.833-16.983h8.962l-6.793,42.456l8.114,1.416l-0.85,5.188L431.055,80.177z"
    />
  <path fill="#474E61" d="M465.063,65.081l0.095,8.02c1.895,0.756,4.341,1.132,7.342,1.132c2.998,0,5.445-0.659,7.341-1.98
    s2.843-3.021,2.843-5.096s-0.711-3.741-2.131-5c-1.422-1.258-3.159-2.358-5.211-3.302c-2.055-0.944-4.106-1.981-6.158-3.114
    c-2.053-1.131-3.789-2.656-5.211-4.575c-1.42-1.918-2.131-4.199-2.131-6.841c0-4.968,1.802-8.728,5.4-11.275
    c3.6-2.547,8.178-3.821,13.736-3.821c4.23,0,8.242,0.566,12.032,1.698l1.988,0.661l-2.274,12.737h-6.158l-0.567-7.359
    c-1.389-0.567-3.442-0.85-6.158-0.85s-4.894,0.566-6.536,1.698s-2.463,2.689-2.463,4.67c0,1.982,0.725,3.649,2.18,5.001
    c1.451,1.354,3.221,2.533,5.306,3.537c2.081,1.008,4.181,2.075,6.298,3.209c2.117,1.132,3.9,2.609,5.354,4.434
    c1.453,1.825,2.178,3.963,2.178,6.416c0,4.907-1.941,8.808-5.825,11.7s-9.048,4.338-15.489,4.338
    c-4.735,0-8.843-0.563-12.314-1.696l-1.896-0.662l2.275-13.68H465.063z"/>
</g>
<g>
  <polygon fill="#FEECB5" points="34.641,0 0,20 0,80.001 17.321,90.001 17.321,70.001 34.641,80.001 51.961,70.001 51.961,90.001 
    69.282,80.001 69.282,60.001 86.603,50.001 86.603,30 		"/>
  <polygon fill="#30384E" points="34.641,80.001 51.961,90.001 51.961,70.001 		"/>
  <polygon fill="#3B4358" points="17.321,90.001 34.641,80.001 17.321,70.001 		"/>
  <polygon fill="#5E6475" points="34.641,20 17.321,30 17.321,50.001 34.641,59.999 51.961,50.001 51.961,30 		"/>
  <polygon fill="#BABDC4" points="34.641,40 51.961,50.001 34.641,59.999 17.321,50.001 		"/>
  <polygon fill="#474E61" points="34.641,40 34.641,20 51.961,30 51.961,50.001 		"/>
  <polygon fill="#FDCD39" points="34.641,59.999 17.321,50.001 17.321,30 0,20 0,80.001 17.321,90.001 17.321,70.001 34.641,80.001 
        "/>
  <polygon fill="#FDDF83" points="51.961,50.001 34.641,59.999 34.641,80.001 51.961,70.001 51.961,90.001 69.282,80.001 
    69.282,60.001 86.603,50.001 86.603,30 69.282,40 69.282,20 51.961,30 		"/>
  <polygon fill="#FEECB5" points="69.282,20 69.282,40 86.603,30 		"/>
  <polygon fill="#FEECB5" points="34.641,20 51.961,30 69.282,20 34.641,0 0,20 17.321,30 		"/>
</g>
</g>
`]
